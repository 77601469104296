import {ModuleKey} from '../ModuleKey';
import createCachedSelector from "re-reselect";
import {createSelector} from "reselect";
import {getUsername} from "../../common/selectors";

const selectDisplayIds = (qs, ids) =>{
  if (ids) {
    return ids.sort((id1,id2) => {
      let p1 = Number.MAX_SAFE_INTEGER;
      let p2 = Number.MAX_SAFE_INTEGER;
      qs.get(id1).references.forEach(ref => {
        if (ref.structure.position < p1) {
          p1 = ref.structure.position;
        }
      });
      qs.get(id2).references.forEach(ref => {
        if (ref.structure.position < p2) {
          p2 = ref.structure.position;
        }
      });
      let cmp = p1 - p2;
      if (cmp === 0) {
        cmp = qs.get(id1).number.localeCompare(qs.get(id2).number);
      }
      return cmp;
    });
  }
  return ids;
};

const getQuestions = (state) => state[ModuleKey].questions;
const getDisplayedQuestionCount = (state) => state[ModuleKey].displayedQuestionCount;
const getUnsortedDisplayedQuestionIds = (state) => state[ModuleKey].displayedQuestionIds;
const getDisplayedQuestionIds =  createSelector(
  [ getQuestions, getUnsortedDisplayedQuestionIds],
  selectDisplayIds
);
const getNeedsReviewFilter = (state) => state[ModuleKey].needsReviewFilter;
const getAssignedFilter = (state) => state[ModuleKey].assignedFilter;
const getAssignedToFilter = (state) => state[ModuleKey].assignedToFilter;
const getDeferrableFilter = (state) => state[ModuleKey].deferrableFilter;
const getStatusFilter = (state) => state[ModuleKey].statusFilter;
const getFilterText = (state) => state[ModuleKey].filterText;
const getFilterString = (state) => `${getNeedsReviewFilter(state)}:${getAssignedFilter(state)}:${getAssignedToFilter(state)}:${getDeferrableFilter(state)}:${getStatusFilter(state)}:${getFilterText(state)}`;

const getQuestionHistory = (state) => state[ModuleKey].questionHistory;
const getDefaultExpanded = (state) => state[ModuleKey].defaultExpanded;

const getQuestionList = createSelector(
  [getQuestions],
  (questions) => questions.toList()
);
const getAssignedQuestions = createSelector(
  [getQuestionList, getUsername],
  (questions, username) => questions.filter(question => question.assignedTo === username)
);
// noinspection IdentifierGrammar
const getNeedsReviewQuestions = createSelector(
  [getQuestionList],
  (questions) => questions.filter(question => question.needsReview)
);
const getIncompleteQuestions = createSelector(
  [getQuestionList],
  (questions) => questions.filter(question => !question.complete)
);
const getQuestionId = (state, props) => props.questionId;
const questionSelector = (extract) => (state, props) => extract(getQuestions(state).get(getQuestionId(state,props)));
const getAssignedTo = questionSelector(question => question && question.assignedTo);
const getComments = questionSelector(question => question && question.comments);
const getDeferrable = questionSelector(question => question && question.deferrable);
const getError = questionSelector(question => question && question.error);
const getNumber = questionSelector(question => question && question.number);
const getOptionListMode = questionSelector(question => question && question.optionListMode);
const getOptions = questionSelector(question => question && question.options);
const getQuestionNote = questionSelector(question => question && question.questionNote);
const getReferences = questionSelector(question => question && question.references);
const getText = questionSelector(question => question && question.text);
const hasError = questionSelector(question => question && question.hasError);
const isComplete = questionSelector(question => question && question.complete);
const isFinalized = questionSelector(question => question && question.finalized);
const isNeedsReview = questionSelector(question => question && question.needsReview);
const isOpen = questionSelector(question => question && question.isOpen);
const getLastEdit = questionSelector(question => question && question.lastEdit);
const isLocked = questionSelector(question => question && question.locked);

const highlightFilterText = (text, filterText) => {
  if (text) {
    if (filterText) {
      return text.replaceAll(new RegExp(`(${filterText})`, 'ig'), `<mark>$1</mark>`);
    } else {
      return text;
    }
  } else {
    return "";
  }
};

const getPreText = (state, props) => highlightFilterText(props.reference.preText, state[ModuleKey].filterText);
const getPostText = (state, props) => highlightFilterText(props.reference.postText, state[ModuleKey].filterText);
const getSelectedText = (state, props) => highlightFilterText(props.reference.selectedText, state[ModuleKey].filterText);
const getDisplayHtml = (state, props) => highlightFilterText(props.reference.displayHtml, state[ModuleKey].filterText);

const getHighlightedText = createCachedSelector(
  [getText, getFilterText],
  (text, filterText) => filterText ? text.replaceAll(new RegExp(`(${filterText})`, 'ig'), `<mark>$1</mark>`) : text
  //cache based on the analysisId, questionId
)((state, props) => `${getAnalysisId(state, props)},${getQuestionId(state, props)}`);

const getAnalyses = (state) => state[ModuleKey].analyses;
const getAnalysisId = (state, props) => {
  return props.analysisId;
};
const analysisSelector = (extract) => createCachedSelector(
  [getAnalyses, getAnalysisId],
  (analyses, id) => extract(analyses.get(id))
)(getAnalysisId);
const getAnalysis = analysisSelector(analysis => analysis);

export {
  getAnalysis,
  getAssignedFilter,
  getAssignedQuestions,
  getAssignedTo,
  getAssignedToFilter,
  getComments,
  getDeferrable,
  getDisplayedQuestionCount,
  getDisplayedQuestionIds,
  getDeferrableFilter,
  getError,
  getFilterText,
  getFilterString,
  getIncompleteQuestions,
  getLastEdit,
  getHighlightedText,
  getNeedsReviewFilter,
  getNeedsReviewQuestions,
  getNumber,
  getOptionListMode,
  getOptions,
  getQuestionHistory,
  getDefaultExpanded,
  getQuestionId,
  getQuestionNote,
  getQuestions,
  getReferences,
  getStatusFilter,
  getText,
  hasError,
  isComplete,
  isFinalized,
  isNeedsReview,
  isOpen,
  getPreText,
  getPostText,
  getSelectedText,
  getDisplayHtml,
  isLocked
};