/*
 * Copyright 2018 General Code
 */
import defaultTo from "lodash/defaultTo";
import {createAction} from 'redux-actions';
import {registerError} from "../../common/actions";
import {deleteJson, getJson, postFormData, postJson, putJson} from "../../common/utils";
import * as selectors from "../selectors";
import {Cookies} from "react-cookie";
import {custId} from "../../common/utils/server-data";

const cookies = new Cookies();

//add appropriate defaults and construct payload
const initQuestion = createAction('QUESTION/INIT', (questionData) => ({questionData}));
const initAnalysis = createAction('ANALYSIS/INIT', (analysisData) => ({analysisData}));

const toggleExpanded = createAction('QUESTION/OPEN/TOGGLE', (id) => ({id}));
const setAllExpanded = createAction('QUESTION/OPEN/All/SET', (analysisId, expanded) => ({analysisId, expanded}));

const toggleLocked = createAction('QUESTION/LOCKED/TOGGLE', id => ({id}));
const toggleLockedFail = createAction('QUESTION/LOCKED/FAIL', id => ({id}));
const setLocked = (questionId, locked) => async (dispatch, getState) => {
  try {
    const question = selectors.getQuestions(getState()).get(questionId);
    const questionDto = {
      id: question.id,
      key: question.key,
      number: question.number,
      text: question.text,
      optionListMode: question.optionListMode,
      assignedTo: question.assignedTo,
      locked: !locked
    };
    await putJson(`/api/question/${questionId}`, questionDto);
    dispatch(toggleLocked(questionId));
  } catch (error) {
    dispatch(registerError("There was a problem setting the assignedTo value", null, [questionId, locked], error));
    dispatch(toggleLockedFail());
  }
};


const clearAnalysisMessage = createAction('ANALYSIS/MESSAGE/CLEAR', (analysisId) => ({analysisId}));

const setAssignedToStart = createAction('QUESTION/ASSIGNED_TO/SET/START', (questionId) => ({questionId}));
const setAssignedToSuccess = createAction('QUESTION/ASSIGNED_TO/SET/SUCCESS', (questionData) => ({questionData}));
const setAssignedToFail = createAction('QUESTION/ASSIGNED_TO/SET/FAIL', (questionId) => ({questionId}));
const setAssignedToFinally = createAction('QUESTION/ASSIGNED_TO/SET/FINALLY', (questionId) => ({questionId}));
const setAssignedTo = (questionId, assignedTo) => async (dispatch, getState) => {
  dispatch(setAssignedToStart(questionId));
  try {
    const question = selectors.getQuestions(getState()).get(questionId);
    const questionDto = {
      id: question.id,
      key: question.key,
      number: question.number,
      text: question.text,
      optionListMode: question.optionListMode,
      needsReview: question.needsReview,
      assignedTo: assignedTo
    };
    const questionData = await putJson(`/api/question/${questionId}`, questionDto);
    dispatch(setAssignedToSuccess(questionData));
  } catch (error) {
    dispatch(registerError("There was a problem setting the assignedTo value", null, [questionId, assignedTo], error));
    dispatch(setAssignedToFail(questionId));
  } finally {
    dispatch(setAssignedToFinally(questionId));
  }
};

const setNeedsReviewStart = createAction('QUESTION/NEEDS_REVIEW/SET/START', (questionId) => ({questionId}));
const setNeedsReviewSuccess = createAction('QUESTION/NEEDS_REVIEW/SET/SUCCESS', (questionData) => ({questionData}));
const setNeedsReviewFail = createAction('QUESTION/NEEDS_REVIEW/SET/FAIL', (questionId) => ({questionId}));
const setNeedsReviewFinally = createAction('QUESTION/NEEDS_REVIEW/SET/FINALLY', (questionId) => ({questionId}));
const setNeedsReview = (questionId, checked) => async (dispatch, getState) => {
  dispatch(setNeedsReviewStart(questionId));
  try {
    const question = selectors.getQuestions(getState()).get(questionId);
    const questionDto = {
      id: question.id,
      key: question.key,
      number: question.number,
      text: question.text,
      optionListMode: question.optionListMode,
      needsReview: checked,
      assignedTo: question.assignedTo
    };
    const questionData = await putJson(`/api/question/${questionId}`, questionDto);
    dispatch(setNeedsReviewSuccess(questionData));
  } catch (error) {
    dispatch(registerError("There was a problem setting the needsReview value", null, [questionId, checked], error));
    dispatch(setNeedsReviewFail(questionId));
  } finally {
    dispatch(setNeedsReviewFinally(questionId));
  }
};

const deleteOptionResponseStart = createAction('OPTION/RESPONSE/DELETE/START', (questionId) => ({questionId}));
const deleteOptionResponseSuccess = createAction('OPTION/RESPONSE/DELETE/SUCCESS', (questionData) => ({questionData}));
const deleteOptionResponseFail = createAction('OPTION/RESPONSE/DELETE/FAIL', (questionId, optionId, error) => ({questionId, optionId, error}));
const deleteOptionResponseFinally = createAction('OPTION/RESPONSE/DELETE/FINALLY', (questionId) => ({questionId}));
const deleteOptionResponse = (questionId, optionId) => async (dispatch) => {
  dispatch(deleteOptionResponseStart(questionId));
  try {
    const questionData = await deleteJson(`/api/option/${optionId}/response`);
    dispatch(deleteOptionResponseSuccess(questionData));
  } catch (error) {
    dispatch(registerError("There was a problem deleting an option response", null, [questionId, optionId], error));
    dispatch(deleteOptionResponseFail(questionId, optionId, error));
  } finally {
    dispatch(deleteOptionResponseFinally(questionId));
  }
};

const updateOptionResponseStart = createAction('OPTION/RESPONSE/UPDATE/START', (questionId) => ({questionId}));
const updateOptionResponseSuccess = createAction('OPTION/RESPONSE/UPDATE/SUCCESS', (questionData) => ({questionData}));
const updateOptionResponseFail = createAction('OPTION/RESPONSE/UPDATE/FAIL', (questionId, optionId, error) => ({questionId, optionId, error}));
const updateOptionResponseFinally = createAction('OPTION/RESPONSE/UPDATE/FINALLY', (questionId) => ({questionId}));
const updateOptionResponse = (questionId, optionId, type, responseValue) => async (dispatch) => {
  dispatch(updateOptionResponseStart(questionId));
  const data = {optionId: optionId, type: type};
  if (responseValue || responseValue === "") {
    data.responseValue = responseValue;
  }
  try {
    const response = await postJson(`/api/option/${optionId}/response`, data);
    dispatch(updateOptionResponseSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem updating an option response", null, [questionId, optionId], error));
    dispatch(updateOptionResponseFail(questionId, optionId, error));
  } finally {
    dispatch(updateOptionResponseFinally(questionId));
  }
};

const addOptionFileStart = createAction('OPTION/FILE/CREATE/START', (questionId) => ({questionId}));
const addOptionFileSuccess = createAction('OPTION/FILE/CREATE/SUCCESS', (questionData) => ({questionData}));
const addOptionFileFail = createAction('OPTION/FILE/CREATE/FAIL', (questionId, optionId, error) => ({questionId, optionId, error}));
const addOptionFileFinally = createAction('OPTION/FILE/CREATE/FINALLY', (questionId) => ({questionId}));
const addOptionFile = (questionId, optionId, file) => async (dispatch) => {
  dispatch(addOptionFileStart(questionId));
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await postFormData(`/api/option/${optionId}/file`, formData);
    response.json().then(updateResponse => {
      dispatch(addOptionFileSuccess(updateResponse));
    });
  } catch (error) {
    dispatch(registerError("There was a problem creating an option response", null, [questionId, optionId], error));
    dispatch(addOptionFileFail(questionId, optionId, error));
  } finally {
    dispatch(addOptionFileFinally(questionId));
  }
};

const removeOptionFileStart = createAction('OPTION/FILE/DELETE/START', (questionId) => ({questionId}));
const removeOptionFileSuccess = createAction('OPTION/FILE/DELETE/SUCCESS', (questionData) => ({questionData}));
const removeOptionFileFail = createAction('OPTION/FILE/DELETE/FAIL', (questionId, optionId, error) => ({questionId, optionId, error}));
const removeOptionFileFinally = createAction('OPTION/FILE/DELETE/FINALLY', (questionId) => ({questionId}));
const removeOptionFile = (questionId, optionId, optionFileId) => async (dispatch) => {
  dispatch(removeOptionFileStart(questionId));
  try {
    const response = await deleteJson(`/api/option/${optionId}/file/${optionFileId}`);
    dispatch(removeOptionFileSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem deleting an option response", null, [questionId, optionId], error));
    dispatch(removeOptionFileFail(questionId, optionId, error));
  } finally {
    dispatch(removeOptionFileFinally(questionId));
  }
};

const checkAnalysisStart = createAction('ANALYSIS/CHECK/START', (analysisId) => ({analysisId}));
const checkAnalysisSuccess = createAction('ANALYSIS/CHECK/SUCCESS', (analysis) => ({analysis}));
const checkAnalysisFail = createAction('ANALYSIS/CHECK/FAIL', (analysisId, error) => ({analysisId, error}));
const checkAnalysisFinally = createAction('ANALYSIS/CHECK/FINALLY', (analysisId) => ({analysisId}));
const checkAnalysis = (analysisId) => async (dispatch) => {
  dispatch(checkAnalysisStart(analysisId));
  try {
    const response = await postJson(`/api/analysis/${analysisId}/check`, {});
    dispatch(checkAnalysisSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem checking the analysis", null, [analysisId], error));
    dispatch(checkAnalysisFail(analysisId, error));
  } finally {
    cookies.remove(`autoCheck-`+custId, {'path': '/'});
    cookies.remove(`lastCheckReminder-`+custId, {'path': '/'});
    dispatch(checkAnalysisFinally(analysisId));
  }
};

const finalizeAnalysisStart = createAction('ANALYSIS/FINALIZE/START', (analysisId) => ({analysisId}));
const finalizeAnalysisSuccess = createAction('ANALYSIS/FINALIZE/SUCCESS', (analysis) => ({analysis}));
const finalizeAnalysisFail = createAction('ANALYSIS/FINALIZE/FAIL', (analysisId, error) => ({analysisId, error}));
const finalizeAnalysisFinally = createAction('ANALYSIS/FINALIZE/FINALLY', (analysisId) => ({analysisId}));
const finalizeAnalysis = (analysisId) => async (dispatch) => {
  dispatch(finalizeAnalysisStart(analysisId));
  try {
    const response = await postJson(`/api/analysis/${analysisId}/finalize`, {});
    dispatch(finalizeAnalysisSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem finalizing the analysis", null, [analysisId], error));
    dispatch(finalizeAnalysisFail(analysisId, error));
  } finally {
    dispatch(finalizeAnalysisFinally(analysisId));
  }
};

const completeAnalysisStart = createAction('ANALYSIS/COMPLETE/START', (analysisId) => ({analysisId}));
const completeAnalysisSuccess = createAction('ANALYSIS/COMPLETE/SUCCESS', (response) => ({response}));
const completeAnalysisFail = createAction('ANALYSIS/COMPLETE/FAIL', (analysisId, error) => ({analysisId, error}));
const completeAnalysisFinally = createAction('ANALYSIS/COMPLETE/FINALLY', (analysisId) => ({analysisId}));
const completeAnalysis = (analysisId) => async (dispatch) => {
  dispatch(completeAnalysisStart(analysisId));
  try {
    const response = await postJson(`/api/analysis/${analysisId}/complete`, {});
    dispatch(completeAnalysisSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem completing the analysis", null, [analysisId], error));
    dispatch(completeAnalysisFail(analysisId, error));
  } finally {
    dispatch(completeAnalysisFinally(analysisId));
  }
};

const addQuestionNoteStart = createAction('QUESTION/NOTE/CREATE/START', (questionId) => ({questionId}));
const addQuestionNoteSuccess = createAction('QUESTION/NOTE/CREATE/SUCCESS', (questionData) => ({questionData}));
const addQuestionNoteFail = createAction('QUESTION/NOTE/CREATE/FAIL', (questionId, error) => ({questionId, error}));
const addQuestionNoteFinally = createAction('QUESTION/NOTE/CREATE/FINALLY', (questionId) => ({questionId}));
const addQuestionNote = (questionId, questionNote, fileDescriptors) => async (dispatch) => {
  dispatch(addQuestionNoteStart(questionId));
  putJson(`/api/question/${questionId}/note`, questionNote).then((response) => {
    if (fileDescriptors) {
      fileDescriptors.forEach((descriptor) => {
        if (descriptor.localfile) {
          dispatch(addQuestionNoteFile(questionId, response.questionNote, descriptor.localfile));
        } else {
          if (descriptor.delete) {
            dispatch(deleteQuestionNoteFile(questionId, response.questionNote, descriptor.questionFile));
          }
        }
      });
    }
    dispatch(addQuestionNoteSuccess(response));
  }).catch((error) => {
    dispatch(registerError("There was a problem creating a note to the edito", null, [questionId], error));
    dispatch(addQuestionNoteFail(questionId, error));
  }).finally(() => {
    dispatch(addQuestionNoteFinally(questionId));
  });
};

const deleteQuestionNoteStart = createAction('QUESTION/NOTE/DELETE/START', (questionId) => ({questionId}));
const deleteQuestionNoteSuccess = createAction('QUESTION/NOTE/DELETE/SUCCESS', (questionData) => ({questionData}));
const deleteQuestionNoteFail = createAction('QUESTION/NOTE/DELETE/FAIL', (questionId, error) => ({questionId, error}));
const deleteQuestionNoteFinally = createAction('QUESTION/NOTE/DELETE/FINALLY', (questionId) => ({questionId}));
const deleteQuestionNote = (questionId, questionNote) => async (dispatch) => {
  dispatch(deleteQuestionNoteStart(questionId));
  try {
    const noteResponse = await deleteJson(`/api/question-note/${questionNote.id}`);
    dispatch(deleteQuestionNoteSuccess(noteResponse));
  } catch (error) {
    dispatch(registerError("There was a problem deleting the note to editor", null, [questionId], error));
    dispatch(deleteQuestionNoteFail(questionId, error));
  } finally {
    dispatch(deleteQuestionNoteFinally(questionId));
  }
};

const addQuestionNoteFileStart = createAction('QUESTION/NOTEFILE/CREATE/START', (questionId) => ({questionId}));
const addQuestionNoteFileSuccess = createAction('QUESTION/NOTEFILE/CREATE/SUCCESS', (questionData) => ({questionData}));
const addQuestionNoteFileFail = createAction('QUESTION/NOTEFILE/CREATE/FAIL', (questionId, error) => ({questionId, error}));
const addQuestionNoteFileFinally = createAction('QUESTION/NOTEFILE/CREATE/FINALLY', (questionId) => ({questionId}));
const addQuestionNoteFile = (questionId, questionNote, file) => async (dispatch) => {
  dispatch(addQuestionNoteFileStart(questionId));
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await postFormData(`/api/question-note/${questionNote.id}/file`, formData);
    response.json().then(updateResponse => {
      dispatch(addQuestionNoteFileSuccess(updateResponse));
    });
  } catch (error) {
    dispatch(registerError("There was a problem adding a file to the note to editor", null, [questionId], error));
    dispatch(addQuestionNoteFileFail(questionId, error));
  } finally {
    dispatch(addQuestionNoteFileFinally(questionId));
  }
};

const deleteQuestionNoteFileStart = createAction('QUESTION/NOTEFILE/DELETE/START', (questionId) => ({questionId}));
const deleteQuestionNoteFileSuccess = createAction('QUESTION/NOTEFILE/DELETE/SUCCESS', (questionData) => ({questionData}));
const deleteQuestionNoteFileFail = createAction('QUESTION/NOTEFILE/DELETE/FAIL', (questionId, error) => ({questionId, error}));
const deleteQuestionNoteFileFinally = createAction('QUESTION/NOTEFILE/DELETE/FINALLY', (questionId) => ({questionId}));
const deleteQuestionNoteFile = (questionId, questionNote, file) => async (dispatch) => {
  dispatch(addQuestionNoteFileStart(questionId));
  try {
    const response = await deleteJson(`/api/question-note/${questionNote.id}/file/${file.id}`);
    dispatch(deleteQuestionNoteFileSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem adding a file to the note to editor", null, [questionId], error));
    dispatch(deleteQuestionNoteFileFail(questionId, error));
  } finally {
    dispatch(deleteQuestionNoteFileFinally(questionId));
  }
};

const resetQuestionResponsesStart = createAction('QUESTION/RESPONSES/RESET/START', (questionId) => ({questionId}));
const resetQuestionResponsesSuccess = createAction('QUESTION/RESPONSES/RESET/SUCCESS', (questionData) => ({questionData}));
const resetQuestionResponsesFail = createAction('QUESTION/RESPONSES/RESET/FAIL', (questionId, error) => ({questionId, error}));
const resetQuestionResponsesFinally = createAction('QUESTION/RESPONSES/RESET/FINALLY', (questionId) => ({questionId}));
const resetQuestionResponses = (questionId) => async (dispatch) => {
  dispatch(resetQuestionResponsesStart(questionId));
  try {
    const questionData = await deleteJson(`/api/question/${questionId}/response`);
    dispatch(resetQuestionResponsesSuccess(questionData));
  } catch (error) {
    dispatch(registerError("There was a problem deleting an option response", null, [questionId], error));
    dispatch(resetQuestionResponsesFail(questionId, error));
  } finally {
    dispatch(resetQuestionResponsesFinally(questionId));
  }
};

const loadQuestionHistoryStart = createAction('QUESTION/HISTORY/LOAD/START', (questionId) => ({questionId}));
const loadQuestionHistorySuccess = createAction('QUESTION/HISTORY/LOAD/SUCCESS', (logEvents) => ({logEvents}));
const loadQuestionHistoryFail = createAction('QUESTION/HISTORY/LOAD/FAIL', (questionId, error) => ({questionId, error}));
const loadQuestionHistoryFinally = createAction('QUESTION/HISTORY/LOAD/FINALLY', (questionId) => ({questionId}));
const loadQuestionHistory = (questionId) => async (dispatch) => {
  dispatch(loadQuestionHistoryStart(questionId));
  try {
    const logEvents = await getJson(`/api/question/${questionId}/log`);
    dispatch(loadQuestionHistorySuccess(logEvents));
  } catch (error) {
    dispatch(registerError("There was a problem loading the question history", null, [questionId], error));
    dispatch(loadQuestionHistoryFail(questionId, error));
  } finally {
    dispatch(loadQuestionHistoryFinally(questionId));
  }
};

const addCommentStart = createAction('QUESTION/COMMENT/ADD/START', (questionId, commentText) => ({questionId, commentText}));
const addCommentSuccess = createAction('QUESTION/COMMENT/ADD/SUCCESS', (questionData) => ({questionData}));
const addCommentFail = createAction('QUESTION/COMMENT/ADD/FAIL', (questionId, commentText, error) => ({questionId, commentText, error}));
const addCommentFinally = createAction('QUESTION/COMMENT/ADD/FINALLY', (questionId, commentText) => ({questionId, commentText}));
const addComment = (questionId, commentText) => async (dispatch) => {
  dispatch(addCommentStart(questionId, commentText));
  try {
    const data = {questionId: parseInt(questionId, 10), text: commentText};
    const response = await postJson(`/api/question/${questionId}/comment`, data);
    dispatch(addCommentSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem saving your question comment", null, [questionId, commentText], error));
    dispatch(addCommentFail(questionId, commentText, error));
  } finally {
    dispatch(addCommentFinally(questionId, commentText));
  }
};

const deleteCommentStart = createAction('QUESTION/COMMENT/DELETE/START', (questionId, commentId) => ({questionId, commentId}));
const deleteCommentSuccess = createAction('QUESTION/COMMENT/DELETE/SUCCESS', (questionData) => ({questionData}));
const deleteCommentFail = createAction('QUESTION/COMMENT/DELETE/FAIL', (questionId, commentId, error) => ({questionId, commentId, error}));
const deleteCommentFinally = createAction('QUESTION/COMMENT/DELETE/FINALLY', (questionId, commentId) => ({questionId, commentId}));
const deleteComment = (questionId, commentId) => async (dispatch) => {
  dispatch(deleteCommentStart(questionId, commentId));
  try {
    const response = await deleteJson(`/api/question/${questionId}/comment/${commentId}`);
    dispatch(deleteCommentSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem deleting your question comment", null, [questionId, commentId], error));
    dispatch(deleteCommentFail(questionId, commentId, error));
  } finally {
    dispatch(deleteCommentFinally(questionId, commentId));
  }
};

const updateCommentStart = createAction('QUESTION/COMMENT/DELETE/START', (questionId, commentId, commentText) => ({questionId, commentId, commentText}));
const updateCommentSuccess = createAction('QUESTION/COMMENT/DELETE/SUCCESS', (questionData) => ({questionData}));
const updateCommentFail = createAction('QUESTION/COMMENT/DELETE/FAIL', (questionId, commentId, commentText, error) => ({questionId, commentId, commentText, error}));
const updateCommentFinally = createAction('QUESTION/COMMENT/DELETE/FINALLY', (questionId, commentId, commentText) => ({questionId, commentId, commentText}));
const updateComment = (questionId, commentId, commentText) => async (dispatch) => {
  dispatch(updateCommentStart(questionId, commentId, commentText));
  try {
    const data = {questionId: parseInt(questionId, 10), text: commentText};
    const response = await putJson(`/api/question/${questionId}/comment/${commentId}`, data);
    dispatch(updateCommentSuccess(response));
  } catch (error) {
    dispatch(registerError("There was a problem updating your question comment", null, [questionId, commentId, commentText], error));
    dispatch(updateCommentFail(questionId, commentId, commentText, error));
  } finally {
    dispatch(updateCommentFinally(questionId, commentId, commentText));
  }
};

const filterQuestions = createAction('QUESTIONS/FILTER',
  ({
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    statusFilter,
    filterText
  }) => ({
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    statusFilter,
    filterText: defaultTo(filterText, "")
  }));
const clearFilters = createAction('QUESTIONS/FILTER/CLEAR');

const displayAllQuestions = createAction('QUESTIONS/LOAD/ALL');
const displayMoreQuestions = createAction('QUESTIONS/LOAD/MORE');

const initializeQuestionsState = createAction('STATE/QUESTIONS/INIT',(loadedState) => ({loadedState}));

export {
  addOptionFile,
  addOptionFileFinally,
  addOptionFileStart,
  addOptionFileSuccess,
  addQuestionNote,
  addQuestionNoteFile,
  addQuestionNoteFileFinally,
  addQuestionNoteFileStart,
  addQuestionNoteFileSuccess,
  addQuestionNoteFinally,
  addQuestionNoteStart,
  addQuestionNoteSuccess,
  checkAnalysis,
  checkAnalysisFinally,
  checkAnalysisStart,
  checkAnalysisSuccess,
  clearAnalysisMessage,
  clearFilters,
  completeAnalysis,
  completeAnalysisFinally,
  completeAnalysisStart,
  completeAnalysisSuccess,
  deleteComment,
  deleteCommentFinally,
  deleteCommentStart,
  deleteCommentSuccess,
  deleteOptionResponse,
  deleteOptionResponseFinally,
  deleteOptionResponseStart,
  deleteOptionResponseSuccess,
  deleteQuestionNote,
  deleteQuestionNoteFile,
  deleteQuestionNoteFileFinally,
  deleteQuestionNoteFileStart,
  deleteQuestionNoteFileSuccess,
  deleteQuestionNoteFinally,
  deleteQuestionNoteStart,
  deleteQuestionNoteSuccess,
  displayAllQuestions,
  displayMoreQuestions,
  filterQuestions,
  finalizeAnalysis,
  finalizeAnalysisFinally,
  finalizeAnalysisStart,
  finalizeAnalysisSuccess,
  initAnalysis,
  initializeQuestionsState,
  initQuestion,
  loadQuestionHistory,
  loadQuestionHistoryFinally,
  loadQuestionHistoryStart,
  loadQuestionHistorySuccess,
  removeOptionFile,
  removeOptionFileFinally,
  removeOptionFileStart,
  removeOptionFileSuccess,
  resetQuestionResponses,
  resetQuestionResponsesFinally,
  resetQuestionResponsesStart,
  resetQuestionResponsesSuccess,
  addComment,
  addCommentFinally,
  addCommentStart,
  addCommentSuccess,
  setAssignedTo,
  setAssignedToFinally,
  setAssignedToStart,
  setAssignedToSuccess,
  setAllExpanded,
  setLocked,
  setNeedsReview,
  setNeedsReviewFinally,
  setNeedsReviewStart,
  setNeedsReviewSuccess,
  toggleExpanded,
  toggleLocked,
  updateComment,
  updateCommentFinally,
  updateCommentStart,
  updateCommentSuccess,
  updateOptionResponse,
  updateOptionResponseFinally,
  updateOptionResponseStart,
  updateOptionResponseSuccess
};