/*
 * Copyright 2018 General Code
 */

import {List, Map, Record} from 'immutable';
import {handleActions} from 'redux-actions';
import {pushState, replaceState} from "../../common/history";
import {archiveId, custId, customer} from "../../common/utils/server-data";
import * as actions from '../actions';

const Status = {
  UNANSWERED: "UNANSWERED",
  COMPLETE: "COMPLETE",
  INCOMPLETE: "INCOMPLETE"
};

const State = Record({
  analysisId: null,
  analyses: Map(),
  questions: Map(),
  displayedQuestionCount: 0,
  displayedQuestionIds: null,
  needsReviewFilter: null,
  assignedFilter: null,
  assignedToFilter: null,
  deferrableFilter: null,
  statusFilter: null,
  filterText: "",
  questionHistory: null,
  defaultExpanded: null
});

const initialState = State({});

const OptionFile = Record({
  id: null,
  originalFilename: null,
  filePath: null
});

const mapOptionFile = (optionFileData) => {
  return (!optionFileData ? null : OptionFile({...optionFileData}));
};

const Option = Record({
  id: null,
  key: null,
  type: null,
  prompt: null,
  optionListMode: null,
  questionId: null,
  parentOptionId: null,
  subOptions: Map(),
  initialResponseDate: null,
  initialResponder: null,
  responseUpdateDate: null,
  responseUpdater: null,
  responseValue: null,
  selected: true,
  files: List(),
  error: null
});

const mapOption = (optionData) => {
  return !optionData ? null : Option({
    ...optionData,
    subOptions: Map(optionData.subOptions.map(o => [o.id, mapOption(o)])),
    files: List(optionData.files.map(f => mapOptionFile(f)))
  });
};

const QuestionNoteFile = Record({
  id: null,
  originalFilename: null,
  filePath: null,
  file: null
});

const mapQuestionNoteFile = (questionNoteFileData) => {
  return (!questionNoteFileData ? null : QuestionNoteFile({...questionNoteFileData}));
};

const QuestionNote = Record({
  id: null,
  content: null,
  delete: false,
  noteFiles: List()
});

const mapQuestionNote = (questionNoteData) => {
  return !questionNoteData ? null : QuestionNote({
    ...questionNoteData,
    noteFiles: (!questionNoteData.noteFiles)?List():List(questionNoteData.noteFiles.map(f => mapQuestionNoteFile(f)))
  });
};

const LogEvent = Record({
  user: null,
  accessTime: null,
  displayMessage: null
});

const mapLogEvent = (logEventData) => {
  return (!logEventData ? null : LogEvent({...logEventData}));
};

const Content = Record({
  guid: null,
  indexNum: null,
  label: null,
  number: null,
  title: null,
  type: null,
  hideNumber: false,
  position: null
});

const Structure = Record({
  id: null,
  content: null,
  guid: null,
  parent: null,
  position: null
});

const mapStructure = (structureData) => {
  return !structureData ? null : Structure({
    ...structureData,
    content: Content(structureData.content),
    parent: mapStructure(structureData.parent)
  });
};

const Reference = Record({
  id: null,
  key: null,
  guid: null,
  structure: null,
  selectedText: null,
  preText: null,
  postText: null,
  displayHtml: null
});

const mapReference = (referenceData) => {
  return !referenceData ? null : Reference({
    ...referenceData,
    structure: mapStructure(referenceData.structure)
  });
};

const QuestionComment = Record({
  id: null,
  questionId: null,
  created: null,
  text: null,
  createdBy: null
});

const mapQuestionComment = (questionCommentData) => {
  return (!questionCommentData ? null : QuestionComment({...questionCommentData}));
};

const Question = Record({
  id: null,
  key: null,
  number: null,
  text: null,
  optionListMode: null,
  needsReview: false,
  assignedTo: null,
  deferrable: false,
  options: Map(),
  references: List(),
  linkedQuestions: List(),
  comments: List(),
  questionNote: null,
  finalized: false,
  locked: false,
  isOpen: false,
  isLoading: false,
  error: null,
  hasError: false,
  complete: false,
  lastEdit: null
});

const mapQuestion = (questionData) => {
  return !questionData ? null : Question({...questionData,
    options: Map(questionData.options.filter(o => !o.parentOptionId).map(o => [o.id, mapOption(o)])),
    references: List(questionData.references.map(r => mapReference(r))),
    linkedQuestions: List(questionData.linkedQuestions.map(q => mapQuestion(q))),
    comments: List(questionData.comments.map(c => mapQuestionComment(c))),
    questionNote: mapQuestionNote(questionData.questionNote),
    hasError: questionHasError(questionData),
    lastEdit: mapLogEvent(questionData.lastEdit)
  });
};

const Analysis = Record({
  id: null,
  created: null,
  checked: false,
  finalized: null,
  dueDate: null,
  questionIds: List(),
  errorLinks: Map(),
  hasPrintPermission: false,
  analysisLoading: false,
  messageTitle: null,
  message: null,
  finalizeSuccess: false
});

const initQuestion = (state, {payload: {questionData}}) =>
  state.setIn(['questions', parseInt(questionData.id, 10)], mapQuestion({...questionData, isOpen: (questionHasError(questionData))}));

const initAnalysis = (state, {payload: {analysisData}}) => {
  // If there is a question we need to scroll to, figure out how many questions down the page it is
  let scrollQuestion = 0;
  if (analysisData.scrollQuestionId) {
    scrollQuestion = analysisData.questionIds.indexOf(parseInt(analysisData.scrollQuestionId, 10)) + 1;
  }
  let displayedQuestionCount = state.get('displayedQuestionCount') ? state.get('displayedQuestionCount') : 0;
  if (displayedQuestionCount < scrollQuestion) {
    displayedQuestionCount = scrollQuestion;
  }
  return state.set('displayedQuestionCount', (displayedQuestionCount + 25))
    .setIn(['analyses', analysisData.id], Analysis({
      ...analysisData,
      questionIds: List(analysisData.questionIds),
      errorLinks: Map(analysisData.errorLinks)
    }));
};

const toggleExpanded = (state, {payload: {id}}) => {
  let isOpen = state.getIn(['questions', id, 'isOpen']);
  return state.setIn(['questions', id, 'isOpen'], !isOpen);
};

const setAllExpanded = (state, {payload: {analysisId, expanded}}) => {
  let analysis = state.getIn(['analyses', analysisId]);
  analysis.questionIds.forEach(questionId => {
    state = state.setIn(['questions', questionId, 'isOpen'], expanded);
  });
  return state.set('defaultExpanded', expanded);
};

const toggleLocked = (state, {payload: {id}}) => {
  let isLocked = state.getIn(['questions', id, 'locked']);
  return state.setIn(['questions', id, 'locked'], !isLocked);
};

const clearAnalysisMessage = (state, {payload: {analysisId}}) => state
  .setIn(['analyses', analysisId, 'messageTitle'], null)
  .setIn(['analyses', analysisId, 'message'], null);

const setQuestionLoading = (state, {payload: {questionId}}) =>
  state.setIn(['questions', questionId, 'isLoading'], true);
const setQuestionNotLoading = (state, {payload: {questionId}}) =>
  state.setIn(['questions', questionId, 'isLoading'], false);
const updateQuestion = (state, {payload: {questionData}}) => {
  let question = state.getIn(['questions', questionData.id]);
  state = state.setIn(['questions', questionData.id], mapQuestion({
    ...questionData,
    isOpen: (questionHasError(questionData) || question.isOpen),
    isLoading: question.isLoading
  })).setIn(['analyses', questionData.analysisId, 'checked'], false);
  return state;
};

const setAssignedToSuccess = (state, {payload: {questionData}}) => {
  state = state.setIn(['questions', questionData.id, 'assignedTo'], questionData.assignedTo)
    .setIn(['questions', questionData.id, 'lastEdit'], mapLogEvent(questionData.lastEdit))
    .setIn(['analyses', questionData.analysisId, 'checked'], false);
  return state;
};

const setNeedsReviewSuccess = (state, {payload: {questionData}}) => {
  state = state.setIn(['questions', questionData.id, 'needsReview'], questionData.needsReview)
    .setIn(['questions', questionData.id, 'error'], questionData.error)
    .setIn(['questions', questionData.id, 'complete'], questionData.complete)
    .setIn(['questions', questionData.id, 'lastEdit'], mapLogEvent(questionData.lastEdit))
    .setIn(['analyses', questionData.analysisId, 'checked'], false);
  return state;
};

const resetQuestionHistory = (state) => state.set('questionHistory', null);
const setQuestionHistory = (state, {payload: {logEvents}}) => state.set('questionHistory', logEvents.map(logEvent => mapLogEvent(logEvent)));

const applyFilters = (state,
  {payload: {
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    statusFilter,
    filterText
  }}
) => {
  let displayedQuestionIds = [];
  for (let question of state.get('questions').valueSeq()) {
    if (!isQuestionFilteredOut(question, needsReviewFilter, assignedFilter, assignedToFilter, statusFilter, deferrableFilter, filterText)) {
      displayedQuestionIds.push(question.id);
    }
  }

  // Update the window history to track the filter changes
  updateWindowHistory(
    displayedQuestionIds,
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    statusFilter,
    filterText
  );

  // Set the state of the filters and the filtered questions
  return state.set('needsReviewFilter', needsReviewFilter)
    .set('assignedFilter', assignedFilter)
    .set('assignedToFilter', assignedToFilter)
    .set('deferrableFilter', deferrableFilter)
    .set('statusFilter', statusFilter)
    .set('filterText', filterText)
    .set('displayedQuestionIds', List(displayedQuestionIds))
  .set('displayedQuestionCount', 25);
};

const isQuestionFilteredOut = (question, needsReviewFilter, assignedFilter, assignedToFilter, statusFilter, deferrableFilter, filterText) => {
  if (typeof needsReviewFilter !== 'undefined' && needsReviewFilter !== null && needsReviewFilter !== question.needsReview) {
    return true;
  }
  if (typeof assignedFilter !== 'undefined' && assignedFilter !== null && assignedFilter !== (question.assignedTo != null && question.assignedTo.length > 0)) {
    return true;
  }
  if (typeof assignedToFilter !== 'undefined' && assignedToFilter !== null && assignedToFilter !== question.assignedTo) {
    return true;
  }
  if (typeof statusFilter !== 'undefined' && statusFilter !== null) {
    if ((statusFilter === Status.UNANSWERED && questionHasAnswer(question))
      || (statusFilter === Status.COMPLETE && !question.complete)
      || (statusFilter === Status.INCOMPLETE && question.complete)
    ) {
      return true;
    }
  }
  if (typeof deferrableFilter !== 'undefined' && deferrableFilter !== null && deferrableFilter !== question.deferrable) {
    return true;
  }
  return typeof filterText !== 'undefined' && filterText != null && !questionContainsFilterText(question, filterText.toLowerCase());
};

const displayAllQuestions = (state) => {
  let displayedQuestionIds = state.get('displayedQuestionIds');
  state = state.set('displayedQuestionCount', displayedQuestionIds.size);
  return state;
};
const displayMoreQuestions = (state) => {
  let displayedQuestionCount = state.get('displayedQuestionCount');
  return state.set('displayedQuestionCount', displayedQuestionCount + 25);
};

const clearFilters = (state) => {
  const questionIds = state.get("questions").keySeq();
  updateWindowHistory(
    questionIds,
    null,
    null,
    null,
    null,
    null,
    ""
  );
  return state.set('needsReviewFilter', null)
    .set('assignedFilter', null)
    .set('assignedToFilter', null)
    .set('deferrableFilter', null)
    .set('statusFilter', null)
    .set('filterText', "")
    .set('displayedQuestionIds', questionIds)
  .set('displayedQuestionCount', 25);
};

const questionContainsFilterText = (question, filter) => {
  return (
    includesText(question.assignedTo, filter)
    || containsFilterTextOptionalQuestion(question.key, filter)
    || containsFilterTextOptionalQuestion(question.number, filter)
    || includesText(question.text, filter)
    || (question.options !== null && question.options.some(option => optionContainsFilterText(option, filter)))
    || (question.references !== null && question.references.some(reference => referenceContainsFilterText(reference, filter)))
  );
};

const includesText = (value, filter) => (value !== null && value.toLowerCase().includes(filter));

//If the filter text starts with Question make it optional
const containsFilterTextOptionalQuestion = (value, filter) => {
  if (value !== null) {
    const lowerValue = value.toLowerCase();
    return lowerValue.includes(filter) || (
      //consider a match as if the value started with question
      //either you are starting to type question or have already typed question
      ("question ".startsWith(filter) || filter.startsWith("question "))
      //the value doesn't start with question
      && !lowerValue.startsWith("question ")
      //search as if it did
      && ("question " + lowerValue).includes(filter)
    );
  }
  return false;
};

const optionContainsFilterText = (option, filter) => (includesText(option.prompt, filter)
    || (option.subOptions != null && option.subOptions.some(subOption => optionContainsFilterText(subOption, filter))));

const referenceContainsFilterText = (reference, filter) => {
  return (includesText(reference.key, filter)
    || includesText(reference.preText, filter)
    || includesText(reference.selectedText, filter)
    || includesText(reference.postText, filter)
  );
};

const initializeQuestionsState = (state, {payload: {loadedState}}) => state
  .set('analysisId', loadedState.analysisId)
  .set('displayedQuestionIds', loadedState.displayedQuestionIds)
  .set('displayedQuestionCount', loadedState.displayedQuestionCount)
  .set('needsReviewFilter', loadedState.needsReviewFilter)
  .set('assignedFilter', loadedState.assignedFilter)
  .set('assignedToFilter', loadedState.assignedToFilter)
  .set('deferrableFilter', loadedState.deferrableFilter)
  .set('statusFilter', loadedState.statusFilter)
  .set('filterText', loadedState.filterText);

const updateWindowHistory = (
  displayedQuestionIds,
  needsReviewFilter,
  assignedFilter,
  assignedToFilter,
  deferrableFilter,
  statusFilter,
  filterText,
  initial
) => {
  let pageFilters = [];
  if (needsReviewFilter != null) {
    pageFilters.push('needsReview=' + needsReviewFilter);
  }
  if (assignedFilter != null) {
    pageFilters.push('assigned=' + assignedFilter);
  }
  if (assignedToFilter != null) {
    pageFilters.push('assignedTo=' + encodeURIComponent(assignedToFilter));
  }
  if (deferrableFilter != null) {
    pageFilters.push('deferrable=' + deferrableFilter);
  }
  if (statusFilter != null) {
    pageFilters.push('status=' + statusFilter);
  }
  if (filterText) {
    pageFilters.push('filterText=' + encodeURIComponent(filterText));
  }
  let custIdString = archiveId ? `${archiveId}/` : (custId ? `${custId}/` : "");
  let pageName = `${(customer && customer.name) ? customer.name : "eCode360®"} Questions`;
  let pageUrl = `/${custIdString}questions${((pageFilters.length > 0) ? "?" + pageFilters.join("&") : "") + (initial ? window.location.hash : "")}`;
  let pageState = {
    displayedQuestionIds: (displayedQuestionIds ? (displayedQuestionIds.toJS ? displayedQuestionIds.toJS() : displayedQuestionIds) : []),
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    filterText,
    statusFilter
  };
  if (initial) {
    replaceState(pageState, pageName, pageUrl);
  } else {
    pushState(pageState, pageName, pageUrl);
  }
  if (window?.gtag) {
    window.gtag('event', 'page_view', {
      page_title: pageName,
      page_location: pageUrl,
      cust_id: custId
    });
  }
};

const setAnalysisLoading = (state, {payload: {analysisId}}) => state.setIn(['analyses', analysisId, 'analysisLoading'], true);
const setAnalysisNotLoading = (state, {payload: {analysisId}}) => state.setIn(['analyses', analysisId, 'analysisLoading'], false);
const handleFinalizeResponse = (state, {payload: {analysis}}) => {
  if (analysis.errorLinks && Object.keys(analysis.errorLinks).length > 0) {
    state = state.setIn(['analyses', analysis.id, 'errorLinks'], analysis.errorLinks)
      .setIn(['analyses', analysis.id, 'finalizeSuccess'], false)
      .setIn(['analyses', analysis.id, 'messageTitle'], 'Finalize Analysis')
      .setIn(['analyses', analysis.id, 'message'], 'Found ' + Object.keys(analysis.errorLinks).length + ' error'
        + (Object.keys(analysis.errorLinks).length > 1 ? 's' : '') + ' while checking this analysis.');
  } else {
    state = state.setIn(['analyses', analysis.id, 'errorLinks'], Map())
      .setIn(['analyses', analysis.id, 'finalizeSuccess'], true)
      .setIn(['analyses', analysis.id, 'messageTitle'], 'Finalize Analysis')
      .setIn(['analyses', analysis.id, 'message'], 'No errors found. Analysis finalized!');
  }
  state = state.setIn(['analyses', analysis.id, 'checked'], analysis.checked !== null)
    .setIn(['analyses', analysis.id, 'finalized'], analysis.finalized);
  for (let questionData of analysis.questions) {
    let question = state.getIn(['questions', questionData.id]);
    state = state.setIn(['questions', questionData.id], mapQuestion(
      {...questionData, isOpen: (questionHasError(questionData) || question.isOpen), isLoading: question.isLoading})
    );
  }
  return state;
};

const handleCheckResponse = (state, {payload: {analysis}}) => {
  if (analysis.errorLinks && Object.keys(analysis.errorLinks).length > 0) {
    state = state.setIn(['analyses', analysis.id, 'errorLinks'], analysis.errorLinks)
      .setIn(['analyses', analysis.id, 'messageTitle'], 'Analysis Completeness Check')
      .setIn(['analyses', analysis.id, 'message'], 'Found ' + Object.keys(analysis.errorLinks).length + ' error'
        + (Object.keys(analysis.errorLinks).length > 1 ? 's' : '') + ' while checking this analysis.');
  } else {
    state = state.setIn(['analyses', analysis.id, 'errorLinks'], Map())
      .setIn(['analyses', analysis.id, 'messageTitle'], 'Analysis Completeness Check')
      .setIn(['analyses', analysis.id, 'message'], 'No errors found. Analysis response is ready to submit.');
  }
  state = state.setIn(['analyses', analysis.id, 'checked'], analysis.checked !== null)
    .setIn(['analyses', analysis.id, 'finalized'], analysis.finalized);
  for (let questionData of analysis.questions) {
    let question = state.getIn(['questions', questionData.id]);
    state = state.setIn(['questions', questionData.id], mapQuestion(
      {...questionData, isOpen: (questionHasError(questionData) || question.isOpen), isLoading: question.isLoading})
    );
  }
  return state;
};

const handleCompleteResponse = (state, {payload: {response}}) => {
  if (response.error === false) {
    window.location.reload();
  }
  return state;
};


const questionHasError = (question) => {
  if (question.error) {
    return true;
  }
  for (let option of (question.options.valueSeq ? question.options.valueSeq() : question.options)) {
    if (optionHasError(option)) {
      return true;
    }
  }
  return false;
};
const optionHasError = (option) => {
  if (option.error) {
    return true;
  }
  for (let subOption of (option.subOptions.valueSeq ? option.subOptions.valueSeq() : option.subOptions)) {
    if (optionHasError(subOption)) {
      return true;
    }
  }
  return false;
};

const questionHasAnswer = (question) => {
  for (let option of (question.options.valueSeq ? question.options.valueSeq() : question.options)) {
    if (option.selected) {
      return true;
    }
  }
  return false;
};

const reducer = handleActions({
  [actions.addCommentFinally] : setQuestionNotLoading,
  [actions.addCommentStart] : setQuestionLoading,
  [actions.addCommentSuccess] : updateQuestion,
  [actions.addOptionFileFinally] : setQuestionNotLoading,
  [actions.addOptionFileStart] : setQuestionLoading,
  [actions.addOptionFileSuccess] : updateQuestion,
  [actions.addQuestionNoteFileFinally] : setQuestionNotLoading,
  [actions.addQuestionNoteFileStart] : setQuestionLoading,
  [actions.addQuestionNoteFileSuccess] : updateQuestion,
  [actions.addQuestionNoteFinally] : setQuestionNotLoading,
  [actions.addQuestionNoteStart] : setQuestionLoading,
  [actions.addQuestionNoteSuccess] : updateQuestion,
  [actions.checkAnalysisFinally] : setAnalysisNotLoading,
  [actions.checkAnalysisStart] : setAnalysisLoading,
  [actions.checkAnalysisSuccess] : handleCheckResponse,
  [actions.clearAnalysisMessage] : clearAnalysisMessage,
  [actions.clearFilters] : clearFilters,
  [actions.completeAnalysisFinally] : setAnalysisNotLoading,
  [actions.completeAnalysisStart] : setAnalysisLoading,
  [actions.completeAnalysisSuccess] : handleCompleteResponse,
  [actions.deleteCommentFinally] : setQuestionNotLoading,
  [actions.deleteCommentStart] : setQuestionLoading,
  [actions.deleteCommentSuccess] : updateQuestion,
  [actions.deleteOptionResponseFinally] : setQuestionNotLoading,
  [actions.deleteOptionResponseStart] : setQuestionLoading,
  [actions.deleteOptionResponseSuccess] : updateQuestion,
  [actions.deleteQuestionNoteFileFinally] : setQuestionNotLoading,
  [actions.deleteQuestionNoteFileStart] : setQuestionLoading,
  [actions.deleteQuestionNoteFileSuccess] : updateQuestion,
  [actions.deleteQuestionNoteFinally] : setQuestionNotLoading,
  [actions.deleteQuestionNoteStart] : setQuestionLoading,
  [actions.deleteQuestionNoteSuccess] : updateQuestion,
  [actions.displayAllQuestions] : displayAllQuestions,
  [actions.displayMoreQuestions] : displayMoreQuestions,
  [actions.filterQuestions] : applyFilters,
  [actions.finalizeAnalysisFinally] : setAnalysisNotLoading,
  [actions.finalizeAnalysisStart] : setAnalysisLoading,
  [actions.finalizeAnalysisSuccess] : handleFinalizeResponse,
  [actions.initAnalysis] : initAnalysis,
  [actions.initQuestion] : initQuestion,
  [actions.initializeQuestionsState] : initializeQuestionsState,
  [actions.loadQuestionHistoryStart] : resetQuestionHistory,
  [actions.loadQuestionHistorySuccess] : setQuestionHistory,
  [actions.removeOptionFileFinally] : setQuestionNotLoading,
  [actions.removeOptionFileStart] : setQuestionLoading,
  [actions.removeOptionFileSuccess] : updateQuestion,
  [actions.resetQuestionResponsesFinally] : setQuestionNotLoading,
  [actions.resetQuestionResponsesStart] : setQuestionLoading,
  [actions.resetQuestionResponsesSuccess] : updateQuestion,
  [actions.setAssignedToFinally] : setQuestionNotLoading,
  [actions.setAssignedToStart] : setQuestionLoading,
  [actions.setAssignedToSuccess] : setAssignedToSuccess,
  [actions.setAllExpanded] : setAllExpanded,
  [actions.setNeedsReviewFinally] : setQuestionNotLoading,
  [actions.setNeedsReviewStart] : setQuestionLoading,
  [actions.setNeedsReviewSuccess] : setNeedsReviewSuccess,
  [actions.toggleExpanded] : toggleExpanded,
  [actions.toggleLocked] : toggleLocked,
  [actions.updateCommentFinally] : setQuestionNotLoading,
  [actions.updateCommentStart] : setQuestionLoading,
  [actions.updateCommentSuccess] : updateQuestion,
  [actions.updateOptionResponseFinally] : setQuestionNotLoading,
  [actions.updateOptionResponseStart] : setQuestionLoading,
  [actions.updateOptionResponseSuccess] : updateQuestion
}, initialState);

export {State, initialState, Question, QuestionNoteFile, updateWindowHistory};
export default reducer;