import * as PropTypes from "prop-types";
import {connect} from 'react-redux';
import {compose} from 'recompose';

import {createStructuredSelector} from 'reselect';

import {toJS} from "../../common/utils";
import {checkAnalysis, clearFilters, filterQuestions} from "../actions";
import {getAnalysisId, getCustomization, getUsername, hasIa2FinalizePermission} from '../../common/selectors';
import QuestionsSidebar from "../components/QuestionsSidebar";
import {
  getAnalysis,
  getAssignedFilter,
  getAssignedToFilter,
  getDeferrableFilter,
  getFilterText,
  getNeedsReviewFilter,
  getQuestions,
  getStatusFilter
} from "../selectors";

const mapStateToProps = createStructuredSelector({
  analysis: getAnalysis,
  filters: createStructuredSelector({
    needsReviewFilter: getNeedsReviewFilter,
    assignedFilter: getAssignedFilter,
    assignedToFilter: getAssignedToFilter,
    deferrableFilter: getDeferrableFilter,
    statusFilter: getStatusFilter,
    filterText: getFilterText,
  }),
  analysisId: getAnalysisId,
  questions: getQuestions,
  currentUser: getUsername,
  hasFinalizePermission: hasIa2FinalizePermission,
  customization: getCustomization
});

const mapDispatchToProps = (dispatch) => ({
  filterQuestions: ({
    needsReviewFilter,
    assignedFilter,
    assignedToFilter,
    deferrableFilter,
    statusFilter,
    filterText
  }) => {
    dispatch(filterQuestions({
      needsReviewFilter,
      assignedFilter,
      assignedToFilter,
      deferrableFilter,
      statusFilter,
      filterText
    }));
  },
  clearFilters: () => dispatch(clearFilters()),
  checkAnalysis: (analysisId) => {
    dispatch(checkAnalysis(analysisId));
  }
});

const QuestionsSidebarContainer = compose(connect(mapStateToProps, mapDispatchToProps), toJS)(QuestionsSidebar);
QuestionsSidebarContainer.propTypes = {
  analysisId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
export default QuestionsSidebarContainer;